import { configureStore } from '@reduxjs/toolkit';
import authAdminReducer from './auth';
import adminReducer from './admin/users/admin';
import optikAdminReducer from './admin/master/optik';
import colorAdminReducer from './admin/master/color';
import coatingAdminReducer from './admin/master/coating';
import customerAdminReducer from './admin/users/customer';
import customerOrderAdminReducer from './admin/order/customer';
import orderAdminReducer from './admin/order';
import customizationReducer from './customizationReducer';
import indeksAdminReducer from './admin/master/indeks';
import lensGroupAdminReducer from './admin/master/lensGroup';
import lensaAdminReducer from './admin/master/lensa';
import customerLensaReducer from './customers/lensa';
import patternAdminReducer from './admin/master/patrun';
import tintingAdminReducer from './admin/master/tintingColor';
import tintingDensityAdminReducer from './admin/master/density';
import tintingGroupAdminReducer from './admin/master/tintingGroup';

// ==============================|| REDUX - MAIN STORE ||============================== //

const store = configureStore({
    reducer: {
        tintingGroup: tintingGroupAdminReducer,
        density: tintingDensityAdminReducer,
        tinting: tintingAdminReducer,
        pattern: patternAdminReducer,
        customerLensa: customerLensaReducer,
        customerOrder: customerOrderAdminReducer,
        order: orderAdminReducer,
        lensa: lensaAdminReducer,
        lensGroup: lensGroupAdminReducer,
        indeks: indeksAdminReducer,
        coating: coatingAdminReducer,
        color: colorAdminReducer,
        optik: optikAdminReducer,
        auth: authAdminReducer,
        users: customerAdminReducer,
        admin: adminReducer,
        customization: customizationReducer
    }
});
const persister = 'Free';

export { store, persister };

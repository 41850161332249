// assets
import { IconDashboard, IconUser, IconBuildingStore, IconDatabase } from '@tabler/icons';

// constant
const icons = { IconDashboard, IconUser, IconBuildingStore, IconDatabase };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const dashboard = {
    id: 'dashboard',
    title: 'MENU UTAMA',
    type: 'group',
    children: [
        {
            id: 'manage-admin',
            title: 'Admin Management',
            type: 'item',
            url: '/admin/manage-admin',
            icon: icons.IconUser,
            breadcrumbs: false,
            allowedPermission: ['super']
        },
        {
            id: 'orders',
            title: 'Order Management',
            type: 'item',
            url: '/admin/orders',
            icon: icons.IconBuildingStore,
            breadcrumbs: true,
            allowedPermission: ['super', 'admin']
        },
        {
            id: 'master-data',
            title: 'Master Data',
            type: 'collapse',
            icon: icons.IconDatabase,
            allowedPermission: ['super', 'admin'],
            children: [
                {
                    id: 'optik',
                    title: 'Optik',
                    type: 'item',
                    url: '/admin/master-data/optik',
                    icon: icons.IconDatabase,
                    breadcrumbs: false,
                    allowedPermission: ['super', 'admin']
                },
                {
                    id: 'account-optik',
                    title: 'Account Optik',
                    type: 'item',
                    url: '/admin/master-data/account-optik',
                    icon: icons.IconDatabase,
                    breadcrumbs: false,
                    allowedPermission: ['super', 'admin']
                },
                {
                    id: 'coating',
                    title: 'Coating',
                    type: 'item',
                    url: '/admin/master-data/coating',
                    icon: icons.IconDatabase,
                    breadcrumbs: false,
                    allowedPermission: ['super', 'admin']
                },
                {
                    id: 'tinting-density',
                    title: 'Tinting Density',
                    type: 'item',
                    url: '/admin/master-data/tinting-density',
                    icon: icons.IconDatabase,
                    breadcrumbs: false,
                    allowedPermission: ['super', 'admin']
                },
                {
                    id: 'tinting-color',
                    title: 'Tinting Color',
                    type: 'item',
                    url: '/admin/master-data/tinting-color',
                    icon: icons.IconDatabase,
                    breadcrumbs: false,
                    allowedPermission: ['super', 'admin']
                },
                {
                    id: 'tinting-group',
                    title: 'Tinting Group',
                    type: 'item',
                    url: '/admin/master-data/tinting-group',
                    icon: icons.IconDatabase,
                    breadcrumbs: false,
                    allowedPermission: ['super', 'admin']
                },
                {
                    id: 'indeks',
                    title: 'Indeks',
                    type: 'item',
                    url: '/admin/master-data/indeks',
                    icon: icons.IconDatabase,
                    breadcrumbs: false,
                    allowedPermission: ['super', 'admin']
                },
                {
                    id: 'lens-group',
                    title: 'Lens Group',
                    type: 'item',
                    url: '/admin/master-data/lens-group',
                    icon: icons.IconDatabase,
                    breadcrumbs: false,
                    allowedPermission: ['super', 'admin']
                },
                {
                    id: 'lensa',
                    title: 'Lensa',
                    type: 'item',
                    url: '/admin/master-data/lensa',
                    icon: icons.IconDatabase,
                    breadcrumbs: false,
                    allowedPermission: ['super', 'admin']
                }
            ]
        }
    ]
};

export default dashboard;

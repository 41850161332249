/* eslint-disable react/no-danger */
import { get } from 'lodash';
import { Typography } from '@mui/material';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal);

function SuccessContent(props) {
    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                padding: '10px'
                // marginBottom: '7px'
            }}
        >
            <svg width="96" height="96" viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg">
                <mask id="successIcon" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="8" y="8" width="80" height="80">
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M8 48C8 25.92 25.92 8 48 8C70.08 8 88 25.92 88 48C88 70.08 70.08 88 48 88C25.92 88 8 70.08 8 48ZM22.8 50.8L37.16 65.16C38.72 66.72 41.28 66.72 42.8 65.16L73.16 34.8C74.72 33.24 74.72 30.72 73.16 29.16C71.6 27.6 69.08 27.6 67.52 29.16L40 56.68L28.44 45.16C26.88 43.6 24.36 43.6 22.8 45.16C22.051 45.9073 21.6301 46.9219 21.6301 47.98C21.6301 49.0381 22.051 50.0527 22.8 50.8Z"
                        fill="black"
                    />
                </mask>
                <g mask="url(#successIcon)">
                    <rect width="96" height="96" fill="#39DA8A" />
                </g>
            </svg>

            <Typography variant="h2" sx={{ fontSize: '1.5rem', fontWeight: 'bold' }}>
                {get(props, 'message', 'Success')}
            </Typography>
        </div>
    );
}

export default function showMessagePopUp(options, eventHandler = {}) {
    const { onClose, onConfirm } = eventHandler;

    if (options.type === 'success') {
        MySwal.fire({
            confirmButtonColor: '#39DA8A',
            html: <SuccessContent {...options} />,
            confirmButtonText: get(options, 'buttonText', 'Kembali'),
            didOpen: () => {
                // `MySwal` is a subclass of `Swal`
                //   with all the same instance & static methods
                // MySwal.clickConfirm();
            },
            didClose: () => (typeof onClose === 'function' ? onClose() : null)
        });
    } else if (options.type === 'error') {
        MySwal.fire({
            confirmButtonColor: '#DA1414',

            // title: (
            //   <Typography>
            //     Gagal
            //   </Typography>
            // ),
            html: (
                <Typography variant="h2" sx={{ fontSize: '1.5rem', fontWeight: 'bold' }}>
                    <div dangerouslySetInnerHTML={{ __html: get(options, 'message', 'Error') }} />
                </Typography>
            ),
            icon: 'error',
            confirmButtonText: get(options, 'buttonText', 'Kembali'),
            didOpen: () => {
                // `MySwal` is a subclass of `Swal`
                //   with all the same instance & static methods
                // MySwal.clickConfirm();
            },
            didClose: () => (typeof onClose === 'function' ? onClose() : null)
        });
    } else if (options.type === 'warning') {
        MySwal.fire({
            html: (
                <Typography variant="h2" sx={{ fontSize: '1.5rem', fontWeight: 'bold' }}>
                    {get(options, 'message', 'Warning')}
                </Typography>
            ),
            icon: 'warning',
            didClose: () => (typeof onClose === 'function' ? onClose() : null)
        }).then((result) => {
            if (result?.isConfirmed) {
                if (typeof onConfirm === 'function') onConfirm();
            }
        });
    } else if (options.type === 'info') {
        MySwal.fire({
            html: (
                <Typography variant="h2" sx={{ fontSize: '1.5rem', fontWeight: 'bold' }}>
                    {get(options, 'message', 'Warning')}
                </Typography>
            ),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#DA1414',
            confirmButtonText: get(options, 'confirmButtonText', 'Hapus'),
            cancelButtonText: 'Batal',
            didClose: () => (typeof onClose === 'function' ? onClose() : null)
        }).then((result) => {
            if (result?.isConfirmed) {
                if (typeof onConfirm === 'function') onConfirm();
            }
        });
    }
}
